import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { ClubsContainer } from './ClubsContainer';
import { Teams } from 'views/Teams';
import { TeamPlayers } from 'views/TeamPlayers';

export const ClubsSwitch: React.FC<RouteComponentProps<{
  id?: string;
}>> = ({ match: { url, path } }) => (
  <React.Fragment>
    <BreadcrumbsItem to={url}>Clubs</BreadcrumbsItem>

    <Switch>
      <Route exact path={path}>
        <ClubsContainer />
      </Route>

      <Route path={`${path}/:id([0-9]+)/teams/:teamId([0-9]+)/players`}>
        <TeamPlayers />
      </Route>

      <Route path={`${path}/:id([0-9]+)/teams`}>
        <Teams />
      </Route>

      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  </React.Fragment>
);
