import { Button, Paper, Toolbar, Typography } from '@material-ui/core';
import {
  RouterLink,
  SortableTable,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { useApiClient } from 'hooks';
import { Club } from 'models';
import * as React from 'react';
import { Config } from 'config';

export const ClubsContainer: React.FC = () => {
  const [{ data: clubs }] = useApiClient<Club[]>((`clubs/get-clubs`));

  const columns: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'address', label: 'Address' },
    { key: 'phone', label: 'Phone' },
    { key: 'primaryEmail', label: 'Primary Email' },
    { key: 'secondaryEmail', label: 'Secondary Email' },
    { key: 'image', label: 'Logo' },
  ];

  const rows =
    clubs?.map((club) => ({
      key: club.id.toString(),
      cells: [
        {
          key: 'name',
          display: (
            <Button
              component={RouterLink}
              to={`/clubs/${club.id.toString()}/teams`}
              variant="text"
              size="small"
              color="secondary"
            >
              {club.name}
            </Button>
          ),
        },
        { key: 'address', display: club.address ?? '' },
        { key: 'phone', display: club.phone ?? '' },
        { key: 'primaryEmail', display: club.primaryEmail ?? '' },
        { key: 'secondaryEmail', display: club.secondaryEmail ?? '' },
        {
          key: 'image',
          display: club.imageUrl ? (
            <img
              src={`${Config.IMAGE_CDN_URL}${club.imageUrl}`}
              alt=""
              style={{ height: 60 }}
            />
          ) : (
            'N/A'
          ),
        },
      ],
    })) ?? [];

  return (
    <Paper>
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">Clubs</Typography>
      </Toolbar>

      <SortableTable
        columns={columns}
        rows={rows}
        emptyTableText="No clubs available yet."
        disablePagination
      />
      
    </Paper>
  );
};
