import {
    Grid,
    makeStyles,
    Paper,
    Toolbar,
    Typography,
    IconButton,
  } from '@material-ui/core';
  import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
  import {
    DatePicker,
    DelayedLinearProgress,
    SortableTable, 
  } from 'components';
  import { SortableTableHeader } from 'components/SortableTable/components';
  import { Config, DateTimeFormat } from 'config';
  import { format } from 'date-fns';
  import { useApiClient, useDebounce } from 'hooks';
  import { Match, Team } from 'models';
  import { SaveMatchLineupDialog } from './components';
  import * as React from 'react';
  
  const useStyles = makeStyles((theme) => ({
    filtersContainer: {
      ...theme.mixins.gutters(),
      marginBottom: theme.spacing(3),
    },
    filterDateInputs: {
      marginTop: theme.spacing(1.25),
    },
    matchContainer: {
      display: 'flex',
    },
    leftMatch: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      minWidth: 200,
    },
    rightMatch: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 200,
    },
    vsSeperater: {
      fontSize: 11,
      padding: 20,
    },
  }));
  
  export const MatchLineups: React.FC = () => {
    const classes = useStyles();
    const [dateFrom, setDateFrom] = React.useState<Date>();
    const [dateTo, setDateTo] = React.useState<Date>();

    const [modalOpen, setModalOpen] = React.useState(false);
    const [selectedMatch, setSelectedMatch] = React.useState<Match>();
    const [selectedTeam, setSelectedTeam] = React.useState<Team>();

    const variables = React.useMemo(
      () => ({
        dateFrom: format(dateFrom ?? new Date(), 'yyyy-MM-dd'),
        dateTo: format(dateTo ?? new Date(), 'yyyy-MM-dd'),
      }),
      [dateFrom, dateTo],
    );
  
    const [{ data, loading }] = useApiClient<Match[]>(
      `match-lineups`,
      useDebounce(variables, 500),
    );
    const columns: SortableTableHeader[] = [
      { key: 'match', label: '',  props: {style: { width: '40%' } } },
      {
        key: 'venue',
        label: 'Venue',
        props: { style: { width: '15%' } },
      },
      {
        key: 'date',
        label: 'Match Date',
        props: { style: { width: '15%' } },
      },
      {
        key: 'code',
        label: 'Match Code',
        props: { style: { width: '15%' } },
      },
      {
        key: 'controls',
        sortable: false,
      },
    ];
  
    const rows =
      data?.map((match, index) => ({
        key: match.id.toString(),
        cells: [
          {
            key: 'match',
            display: (
              <div className={classes.matchContainer}>
                <div className={classes.leftMatch}>
                  <Typography variant="subtitle2">
                    {match.home?.teamName + (match.home?.team.teamCategory ? '-' + match.home?.team.teamCategory?.name : '')}
                  </Typography>
                  {match.home?.team.club.imageUrl ? (
                    <img
                      src={`${Config.IMAGE_CDN_URL}${match.home.team.club.imageUrl}`}
                      alt={`${match.home.team.club.name} logo`}
                      style={{ height: 22, marginLeft: 8 }}
                    />
                  ) : null}
                </div>
                <div>
                  <span className={classes.vsSeperater}>vs</span>
                </div>
                <div className={classes.rightMatch}>
                  {match.away?.team.club.imageUrl ? (
                    <img
                      src={`${Config.IMAGE_CDN_URL}${match.away.team.club.imageUrl}`}
                      alt={`${match.away.team.club.name} logo`}
                      style={{ height: 22, marginRight: 8 }}
                    />
                  ) : null}
                  <Typography variant="subtitle2">
                    {match.away?.teamName + (match.away?.team.teamCategory ? '-' + match.away?.team.teamCategory?.name : '')}
                  </Typography>
                </div>
              </div>
            ),
          },
          { key: 'venue', display: match.venue?.name ?? 'N/A' },
          {
            key: 'date',
            display: match.matchDate
              ? format(new Date(match.matchDate), DateTimeFormat.MEDIUM)
              : 'N/A',
          },
          { key: 'code', display: match.code ?? 'N/A' },
          {
            key: 'controls',
            display: (
              <div>
                {match.clubOfficials &&
                  match.clubOfficials.length > 0 &&
                  match.clubOfficials[0].clubId === match.home.team.clubId && (
                    <IconButton
                      onClick={() => {
                        setSelectedMatch(match);
                        setSelectedTeam(match.home.team);
                        setModalOpen(true);
                      }}
                    >
                      <img
                        src={`${Config.IMAGE_CDN_URL}${match.home.team.club.imageUrl}`}
                        alt={`${match.home.team.club.name} logo`}
                        style={{ height: 22 }}
                      />
                    </IconButton>
                  )}
                {match.clubOfficials &&
                  match.clubOfficials.length > 0 &&
                  match.clubOfficials[0].clubId === match.away.team.clubId && (
                    <IconButton
                      onClick={() => {
                        setSelectedMatch(match);
                        setSelectedTeam(match.away.team);
                        setModalOpen(true);
                      }}
                    >
                      <img
                        src={`${Config.IMAGE_CDN_URL}${match.away.team.club.imageUrl}`}
                        alt={`${match.away.team.club.name} logo`}
                        style={{ height: 22 }}
                      />
                    </IconButton>
                  )}
              </div>
            ),
          },
        ],
      })) ?? [];
  
    return (
      <React.Fragment>
        <Paper style={{ marginBottom: 16 }}>
          {loading && <DelayedLinearProgress />}
          <Toolbar style={{ justifyContent: 'space-between' }}>
            <Typography variant="h6">Matches</Typography>
          </Toolbar>
          <div className={classes.filtersContainer}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <DatePicker
                  label="Date From"
                  value={dateFrom || new Date()}
                  onChange={(e: MaterialUiPickersDate) =>
                    setDateFrom(e ?? undefined)
                  }
                  fullWidth
                  clearable
                  className={classes.filterDateInputs}
                />
              </Grid>
  
              <Grid item xs={12} md={6}>
                <DatePicker
                  label="Date To"
                  value={dateTo || new Date()}
                  onChange={(e: MaterialUiPickersDate) =>
                    setDateTo(e ?? undefined)
                  }
                  fullWidth
                  clearable
                  className={classes.filterDateInputs}
                />
              </Grid>
            </Grid>
          </div>
          <SortableTable
            columns={columns}
            rows={rows}
            disableSorting={true}
            emptyTableText="No Matches available yet."
            disablePagination
          />

            <SaveMatchLineupDialog
                match={selectedMatch}
                team={selectedTeam}
                dialogProps={{
                    open: modalOpen,
                    onClose: (e) => setModalOpen(false),
                    fullWidth: true,
                    maxWidth: 'sm',
                }}
            />
        </Paper>
      </React.Fragment>
    );
  };