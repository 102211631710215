import { Button, Paper, Toolbar, Typography } from '@material-ui/core';
import {
  RouterLink,
  SortableTable,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { useApiClient } from 'hooks';
import { Club } from 'models';
import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useParams, useRouteMatch } from 'react-router';

interface Params {
  id: string;
}

export const Teams: React.FC = () => {
  const match = useRouteMatch<Params>();
  const { id: clubId } = useParams<Params>();


  const [{ data: club }] = useApiClient<Club>(
    `clubs/teams/${clubId}`,
  );


  const columns: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'category', label: 'Category' },
  ];

  const rows =
    club?.teams.map((team) => ({
      key: team.id.toString(),
      cells: [
        {
          key: 'name',
          display: (
            <Button
              component={RouterLink}
              to={`/clubs/${team.clubId.toString()}/teams/${team.id.toString()}/players`}
              variant="text"
              size="small"
              color="secondary"
            >
              {team.name}
            </Button>
          ),
        },
        { key: 'category', display: team.teamCategory?.name ?? '' },
      ],
    })) ?? [];

  return (
    <React.Fragment>
      {match && (
        <BreadcrumbsItem to={match.url}>{club?.name}</BreadcrumbsItem>
      )}

      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Teams</Typography>
        </Toolbar>

        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No teams available yet."
          disablePagination
        />

      </Paper>
    </React.Fragment>
  );
};
