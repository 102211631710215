import {
  IconButton,
  makeStyles,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { SortableTable, Report } from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { Config, DateTimeFormat } from 'config';
import { format } from 'date-fns';
import { useApiClient } from 'hooks';
import { Competition } from 'models';
import { Match } from 'models/Match';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { GameControls } from 'enums/GameControls';
import { Assessment } from '@material-ui/icons';
import { useReactToPrint } from 'react-to-print';
import { DelayedLinearProgress } from 'components/DelayedLinearProgress';

interface Params {
  competitionId: string;
}

const useStyles = makeStyles({
  matchContainer: {
    display: 'flex',
  },
  leftMatch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minWidth: 200,
  },
  rightMatch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minWidth: 200,
  },
  vsSeperater: {
    fontSize: 11,
    padding: 20,
  },
});

export const Fixtures: React.FC = () => {
  const classes = useStyles();
  const { competitionId } = useParams<Params>();

  const [{ data: competition, loading, error }] = useApiClient<Competition>(
    `competitions/club-matches/${competitionId}`,
  );

  const [printId, setPrintId] = React.useState<number>();

  const refs = React.useRef([] as any);
  const addToRefs = (el: any) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => refs.current.find((m: any) => m.id === printId?.toString()),
    documentTitle: 'Handball_MatchReport_' + printId,
  });

  React.useEffect(() => {
    if (!printId) {
      return;
    }
    handlePrint && handlePrint();
    setPrintId(undefined);
  }, [printId, handlePrint]);

  const groupedRounds = competition?.matches.reduce<
    { round: number; matchday: number; matches: Match[] }[]
  >((prev, curr) => {
    const matchday = prev.find((r) => r.matchday === curr.matchDay);

    if (!matchday) {
      return [
        ...prev,
        { round: curr.matchRound, matchday: curr.matchDay, matches: [curr] },
      ];
    }
    matchday.matches.push(curr);

    return prev;
  }, []);

  return (
    <React.Fragment>
      <DelayedLinearProgress loading={loading} />

      {error && (
        <Typography color="error" component="span">
          {error}
        </Typography>
      )}

      <Paper style={{ marginBottom: 16 }}>
        {groupedRounds?.map((round, index) => {
          const columns: SortableTableHeader[] = [
            { key: 'match', label: '', props: { style: { width: '40%' } } },
            {
              key: 'venue',
              label: 'Venue',
              props: { style: { width: '15%' } },
            },
            {
              key: 'date',
              label: 'Match Date',
              props: { style: { width: '15%' } },
            },
            {
              key: 'code',
              label: 'Match Code',
              props: { style: { width: '15%' } },
            },
            { key: 'controls', sortable: false },
          ];

          const rows =
            round.matches.map((match) => ({
              key: match.id.toString(),
              cells: [
                {
                  key: 'match',
                  display: (
                    <div className={classes.matchContainer}>
                      <div className={classes.leftMatch}>
                        <Typography variant="subtitle2">
                          {match.home.teamName}
                        </Typography>
                        {match.home.team.club.imageUrl ? (
                          <img
                            src={`${Config.IMAGE_CDN_URL}${match.home.team.club.imageUrl}`}
                            alt={`${match.home.team.club.name} logo`}
                            style={{ height: 22, marginLeft: 8 }}
                          />
                        ) : null}
                        {match.matchUpdates.length > 0 ? (
                          <Typography
                            style={{ paddingLeft: 5, fontWeight: 600 }}
                            variant="subtitle2"
                          >
                            {
                              match.matchUpdates.filter(
                                (u) =>
                                  [
                                    GameControls.GOAL,
                                    GameControls.M7_GOAL,
                                  ].includes(u.matchUpdateTypeId) &&
                                  u.teamId === match.home.teamId,
                              ).length
                            }
                          </Typography>
                        ) : null}
                      </div>
                      <div>
                        <span className={classes.vsSeperater}>vs</span>
                      </div>
                      <div className={classes.rightMatch}>
                        {match.matchUpdates.length > 0 ? (
                          <Typography
                            style={{ paddingRight: 5, fontWeight: 600 }}
                            variant="subtitle2"
                          >
                            {
                              match.matchUpdates.filter(
                                (u) =>
                                  [
                                    GameControls.GOAL,
                                    GameControls.M7_GOAL,
                                  ].includes(u.matchUpdateTypeId) &&
                                  u.teamId === match.away.teamId,
                              ).length
                            }
                          </Typography>
                        ) : null}
                        {match.away.team.club.imageUrl ? (
                          <img
                            src={`${Config.IMAGE_CDN_URL}${match.away.team.club.imageUrl}`}
                            alt={`${match.away.team.club.name} logo`}
                            style={{ height: 22, marginRight: 8 }}
                          />
                        ) : null}
                        <Typography variant="subtitle2">
                          {match.away.teamName}
                        </Typography>
                      </div>
                    </div>
                  ),
                },
                { key: 'venue', display: match.venue?.name ?? 'N/A' },
                {
                  key: 'date',
                  display: match.matchDate
                    ? format(new Date(match.matchDate), DateTimeFormat.MEDIUM)
                    : 'N/A',
                },
                { key: 'code', display: match.code ?? 'N/A' },
                {
                  key: 'controls',
                  display: match.endDate && match.isAuthorised && (
                    <div style={{ textAlign: 'right' }}>
                      <IconButton
                        onClick={() => {
                          setPrintId(match.id);
                        }}
                      >
                        <Assessment />
                        <div hidden>
                          <div
                            id={match.id.toString()}
                            key={match.id}
                            ref={addToRefs}
                          >
                            <Report matchId={match.id} />
                          </div>
                        </div>
                      </IconButton>
                    </div>
                  ),
                },
              ],
            })) ?? [];

          return (
            <React.Fragment key={index}>
              <Toolbar
                style={{ minHeight: 'auto', paddingTop: 16, paddingLeft: 16 }}
              >
                <Typography variant="h6">
                  Round {round.round} - Match day {round.matchday}
                </Typography>
              </Toolbar>

              <SortableTable
                columns={columns}
                rows={rows}
                emptyTableText="No fixtures generated"
                tableProps={{ size: 'small' }}
                disableSorting
                disablePagination
              />
            </React.Fragment>
          );
        })}
      </Paper>
    </React.Fragment>
  );
};
