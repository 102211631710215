import { Tournament } from 'components';
import { useApiClient } from 'hooks';
import { KnockoutBracket } from 'models';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { DelayedLinearProgress } from 'components/DelayedLinearProgress';
import { Typography } from '@material-ui/core';

interface Params {
  competitionId: string;
}

export const Knockout: React.FC = () => {
  const { competitionId } = useParams<Params>();

  const [{ data: brackets, refetch, loading, error }] = useApiClient<
    KnockoutBracket[]
  >(`matches/club-knockout-bracket/${competitionId}`);

  return loading ? (
    <DelayedLinearProgress />
  ) : (
    <React.Fragment>
      {error && (
        <Typography color="error" component="span">
          {error}
        </Typography>
      )}

      <Tournament brackets={brackets ?? []} refetch={refetch} />
    </React.Fragment>
  );
};
