import { Button, Paper, Toolbar, Typography } from '@material-ui/core';
import {
  RouterLink,
  SortableTable,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { useApiClient } from 'hooks';
import {  SeasonCategory } from 'models';
import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useParams, useRouteMatch } from 'react-router';

interface Params {
  categoryId: string;
  id:string;
}

export const SeasonCompetitions: React.FC = () => {
  const match = useRouteMatch<Params>();
  const { categoryId } = useParams<Params>();

  const [{ data: categoryCompetitions }] = useApiClient<
    SeasonCategory
  >(`season-categories/club-competitions/${categoryId}`);
  const columns: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'rounds', label: 'Number of rounds' },
    { key: 'type', label: 'Competition Type' },
  ];

  const rows =
    categoryCompetitions?.competitions.map((competition) => ({
      key: competition.id.toString(),
      cells: [
        {
          key: 'name',
          display:
            competition.matches.length > 0 ? (
              <Button
                component={RouterLink}
                to={`/seasons/${categoryCompetitions?.seasonId}/categories/${competition.seasonCategoryId}/competitions/${competition.id}`}
                variant="text"
                size="small"
                color="secondary"
              >
                {competition.name}
              </Button>
            ) : (
              competition.name
            ),
        },
        { key: 'rounds', display: competition.rounds },
        { key: 'type', display: competition.competitionType.name },
      ],
    })) ?? [];

  return (
    <React.Fragment>
      {match && (
        <React.Fragment>
          <BreadcrumbsItem
            to={`/seasons/${categoryCompetitions?.seasonId}/categories`}
          >
            {categoryCompetitions?.season?.name}
          </BreadcrumbsItem>
          <BreadcrumbsItem to={match.url}>
            {categoryCompetitions?.name}
          </BreadcrumbsItem>
        </React.Fragment>
      )}

      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Competitions</Typography>

        </Toolbar>

        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No competitions available yet."
          disablePagination
        />
      </Paper>
    </React.Fragment>
  );
};
