import { Button, Paper, Toolbar, Typography } from '@material-ui/core';
import { RouterLink, SortableTable } from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { useApiClient } from 'hooks';
import { Season } from 'models';
import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useParams, useRouteMatch } from 'react-router';

interface Params {
  id: string;
}

export const SeasonCategories: React.FC = () => {
  const match = useRouteMatch<Params>();
  const { id: seasonId } = useParams<Params>();

  const [{ data }] = useApiClient<Season[]>('seasons/clubs');
  const seasons = data ?? [];
  const season = seasons.find((x) => x.id === parseInt(seasonId));

  const columns: SortableTableHeader[] = [{ key: 'name', label: 'Name' }];

  const rows =
    season?.seasonCategories.map((category) => ({
      key: category.id.toString(),
      cells: [
        {
          key: 'name',
          display: (
            <Button
              component={RouterLink}
              to={`/seasons/${category.seasonId.toString()}/categories/${category.id.toString()}/competitions`}
              variant="text"
              size="small"
              color="secondary"
            >
              {category.name}
            </Button>
          ),
        },
      ],
    })) ?? [];

  return (
    <React.Fragment>
      {match && (
        <BreadcrumbsItem to={match.url}>{season?.name}</BreadcrumbsItem>
      )}

      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Categories</Typography>
        </Toolbar>

        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No categories available yet."
          disablePagination
        />
      </Paper>
    </React.Fragment>
  );
};
