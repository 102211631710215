import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { SeasonCompetitions } from 'views/SeasonCompetitions';
import { Matches } from 'views/Matches';
import { SeasonCategories } from 'views/SeasonCategories';
import { SeasonsContainer } from './SeasonsContainer';

export const SeasonsSwitch: React.FC<RouteComponentProps<{
  id?: string;
}>> = (props) => {
  const {
    match: { url, path },
  } = props;

  return (
    <React.Fragment>
      <BreadcrumbsItem to={url}>Seasons</BreadcrumbsItem>

      <Switch>
        <Route exact path={path}>
          <SeasonsContainer />
        </Route>

        <Route
          path={`${path}/:id([0-9]+)/categories/:categoryId([0-9]+)/competitions/:competitionId([0-9]+)`}
        >
          <Matches {...props} />
        </Route>

        <Route
          path={`${path}/:id([0-9]+)/categories/:categoryId([0-9]+)/competitions`}
        >
          <SeasonCompetitions />
        </Route>

        <Route path={`${path}/:id([0-9]+)/categories`}>
          <SeasonCategories />
        </Route>

        <Route>
          <Redirect to={path} />
        </Route>
      </Switch>
    </React.Fragment>
  );
};
