import { Button, Paper, Toolbar, Typography } from '@material-ui/core';
import {
  RouterLink,
  SortableTable,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { useApiClient } from 'hooks';
import { Season } from 'models';
import * as React from 'react';

export const SeasonsContainer: React.FC = () => {
  const [{ data }] = useApiClient<Season[]>('seasons/clubs');

  const seasons = data ?? [];

  const columns: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'year', label: 'Year' },
  ];

  const rows = seasons.map((season) => ({
    key: season.id.toString(),
    cells: [
      {
        key: 'name',
        display: (
          <Button
            component={RouterLink}
            to={`/seasons/${season.id.toString()}/categories`}
            variant="text"
            size="small"
            color="secondary"
          >
            {season.name}
          </Button>
        ),
      },
      { key: 'year', display: season.seasonYear ?? '' },
    ],
  }));

  return (
    <Paper>
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">Seasons</Typography>
      </Toolbar>

      <SortableTable
        columns={columns}
        rows={rows}
        emptyTableText="No Seasons available yet."
        disablePagination
      />   
    </Paper>
  );
};
