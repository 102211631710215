import { 
    DialogProps,
    Grid,
    makeStyles,
    IconButton,
    Toolbar,
    Typography,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
  } from '@material-ui/core';
  import { Autocomplete } from '@material-ui/lab';
  import { ApiClient } from 'ApiClient';
  import { FormDialog, SnackbarContext, ResponsiveIconButton, EmptyView, FormField,} from 'components';
  import { useApiClient, usePrevious } from 'hooks';
  import { ClubOfficial, Match, TeamPlayer, Team } from 'models';
  import * as React from 'react';
  import AddIcon from '@material-ui/icons/Add';
  import RemoveIcon from '@material-ui/icons/RemoveCircle';
  import { format, subHours, subMinutes } from 'date-fns';
  
  interface Props {
    dialogProps: DialogProps;
    match?: Match;
    team?: Team;
  }
  
  interface MatchForm {
    matchLineupPlayers?: {
      id?:number;
      teamId?: number;
      gameNumber?: string;
      playerId?: number;
    }[];
    matchLineupOfficials?: {
      id?:number;
      teamId?: number;
      gameNumber?: string;
      clubOfficialId?: number;
    }[];
  }

  interface ClubOfficialGameOptions {
    key: string;
    label: string;
  }

  const clubOfficialGameOptions: ClubOfficialGameOptions[] = [
    { key: 'A', label: 'A' },
    { key: 'B', label: 'B' },
    { key: 'C', label: 'C' },
    { key: 'D', label: 'D' },
  ];
  
  export const useStyles = makeStyles((theme) => ({
    toolbar: {
      justifyContent: 'space-between',
      minHeight: 0,
      paddingRight: 0,
      paddingLeft: 0,
    },
    removeIcon: {
      color: theme.palette.danger.main,
    },
    table: {
      marginBottom: theme.spacing(5),
    },
    narrowPadding: {
      padding: theme.spacing(0, 1),
    },
  }));

  export const SaveMatchLineupDialog: React.FC<Props> = ({
    dialogProps,
    match,
    team
  }) => {
    const snackbar = React.useContext(SnackbarContext);
    const classes = useStyles();
    
    const [{ data: matchData, refetch }] = useApiClient<Match>(
      `team-lineup/${match?.id}/${team?.id}`,
    );

    const getForm = React.useCallback(function getForm(
      match?: Match,
    ): MatchForm {
      return {
        matchLineupPlayers: match?.matchLineups && match?.matchLineups?.filter((c) => c.playerId !== null).length > 0 ? match?.matchLineups?.filter((c) => c.playerId !== null && c.player?.isActive).map((t) => ({
          id: t.id,
          teamId: t.teamId,
          gameNumber: t.gameNumber,
          playerId: t.playerId ?? undefined,
        })) : match?.teamPlayers?.filter((c) => c.playerId !== null).map((t) => ({
          teamId: t.teamId,
          gameNumber: t.shirtNumber ? t.shirtNumber.toString() : '',
          playerId: t.playerId,
        })),
        matchLineupOfficials: match?.matchLineups?.filter((c) => c.clubOfficialId !== null).map((t) => ({
          id: t.id,
          teamId: t.teamId,
          gameNumber: t.gameNumber,
          clubOfficialId: t.clubOfficialId ?? undefined
        })),
      };
    },
    []);

    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState(getForm(matchData));
    const [isDisabled, setIsDisabled] = React.useState(false);
    
    const prevMatchData = usePrevious(matchData);;
    
    React.useEffect(() => {
      if (match !== undefined) {
        var disbaled = !(match && (new Date() >= new Date(format(subHours(new Date(match?.matchDate), 24), 'yyyy-MM-dd HH:mm:ss')) && new Date() <= new Date(format(subMinutes(new Date(match?.matchDate), 30), 'yyyy-MM-dd HH:mm:ss'))));
        setIsDisabled(disbaled);
      }
    }, [match]);

    React.useEffect(() => {
      if (prevMatchData !== matchData) {
        setForm(getForm(matchData));
      }
    }, [getForm, matchData, prevMatchData]);

    return (
      <FormDialog
        title={`${match ? 'Edit' : 'Add'} Match Lineup of ${match?.home.teamName} vs ${match?.away.teamName}`}
        submitText="Save"
        disableSubmitButton={!(match && (new Date() >= new Date(format(subHours(new Date(match?.matchDate), 24), 'yyyy-MM-dd HH:mm:ss')) && new Date() <= new Date(format(subMinutes(new Date(match?.matchDate), 30), 'yyyy-MM-dd HH:mm:ss'))))}
        onSubmit={submit}
        loading={loading}
        dialogProps={dialogProps}
      >
        <Grid container>
        <Grid item xs={12}>
        {isDisabled && <Typography color="error" variant="h6" component="span"> 
            Lineup can be added from 24 hours up to 30 minutes before the game
              </Typography>}
        </Grid>
        <Grid item xs={12}>
            <Toolbar className={classes.toolbar}>
              <Typography variant="h6">Club Official</Typography>
  
              <ResponsiveIconButton
                onClick={onAddClubOfficialTeam}
                color="primary"
                icon={AddIcon}
                disabled={!(match && (new Date() >= new Date(format(subHours(new Date(match?.matchDate), 24), 'yyyy-MM-dd HH:mm:ss')) && new Date() <= new Date(format(subMinutes(new Date(match?.matchDate), 30), 'yyyy-MM-dd HH:mm:ss'))))}
              >
                Add Club official To lineup
              </ResponsiveIconButton>
            </Toolbar>
  
            {!form.matchLineupOfficials?.filter((c) => c.clubOfficialId !== null) || form.matchLineupOfficials?.filter((c) => c.clubOfficialId !== null).length === 0 ? (
              <EmptyView>No Club official in Lineup</EmptyView>
            ) : (
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.narrowPadding}>
                    Club Official
                    </TableCell>
                    <TableCell className={classes.narrowPadding}>
                    Game Letter
                    </TableCell>
  
                    <TableCell padding="none" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {form.matchLineupOfficials?.map((v, i) => (
                    <TableRow key={i}>
                      <TableCell style={{width: 300}} className={classes.narrowPadding}>
                      <Autocomplete style={{ marginTop: 9 }}
                        value={matchData?.clubOfficials?.find((s) => v.clubOfficialId && s.id === parseInt(v.clubOfficialId.toString())) || null}
                        multiple={false}
                        disabled={!(match && (new Date() >= new Date(format(subHours(new Date(match?.matchDate), 24), 'yyyy-MM-dd HH:mm:ss')) && new Date() <= new Date(format(subMinutes(new Date(match?.matchDate), 30), 'yyyy-MM-dd HH:mm:ss'))))}
                        options={
                          matchData?.clubOfficials?.filter(
                            (t) =>
                                !form.matchLineupOfficials
                                ?.filter((c) => Boolean(c.clubOfficialId))
                                .map((c) => c.clubOfficialId)
                                .includes(t.id)
                          ) ?? []
                        }
                        getOptionLabel={(option: ClubOfficial) => option?.name + ' ' + option?.surname ?? ''}
                        onChange={(e: React.ChangeEvent<{}>, clubOfficial: ClubOfficial | null) =>
                          onChangeClubOfficialLineup(clubOfficial?.id, 'clubOfficialId', i)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="clubOfficialId"
                            variant="standard"
                            label=""
                            inputProps={{
                              ...params.inputProps,
                            }}
                            required={true}
                          />
                        )}
                      />
                      </TableCell>
  
                      <TableCell className={classes.narrowPadding}>
                      <Autocomplete
                          value={
                            clubOfficialGameOptions.find((s) => s.key === v.gameNumber) || null
                          }
                          options={clubOfficialGameOptions?.filter(
                            (t) =>
                                !form.matchLineupOfficials
                                ?.filter((c) => Boolean(c.gameNumber))
                                .map((c) => c.gameNumber)
                                .includes(t.key)
                          ) ?? []}
                          getOptionLabel={(option: ClubOfficialGameOptions) => option?.label ?? ''}
                          disabled={!(match && (new Date() >= new Date(format(subHours(new Date(match?.matchDate), 24), 'yyyy-MM-dd HH:mm:ss')) && new Date() <= new Date(format(subMinutes(new Date(match?.matchDate), 30), 'yyyy-MM-dd HH:mm:ss'))))}
                          onChange={(
                            e: React.ChangeEvent<{}>,
                            option: ClubOfficialGameOptions | null,
                          ) =>
                          onChangeClubOfficialLineup(option?.key ?? null, 'gameNumber', i)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="gameNumber"
                              variant="standard"
                              label=""
                              required
                            />
                          )}
                        />
                      </TableCell>
  
                      <TableCell padding="none">
                        <IconButton
                          className={classes.removeIcon}
                          onClick={(e) => onRemoveClubOfficialTeam(e, i)}
                          disabled={!(match && (new Date() >= new Date(format(subHours(new Date(match?.matchDate), 24), 'yyyy-MM-dd HH:mm:ss')) && new Date() <= new Date(format(subMinutes(new Date(match?.matchDate), 30), 'yyyy-MM-dd HH:mm:ss'))))}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Grid>
          <Grid item xs={12}>
            <Toolbar className={classes.toolbar}>
              <Typography variant="h6">Player</Typography>
              <ResponsiveIconButton
                onClick={onAddPlayerTeam}
                color="primary"
                icon={AddIcon}
                disabled={!(match && (new Date() >= new Date(format(subHours(new Date(match?.matchDate), 24), 'yyyy-MM-dd HH:mm:ss')) && new Date() <= new Date(format(subMinutes(new Date(match?.matchDate), 30), 'yyyy-MM-dd HH:mm:ss'))))}
              >
                Add Player to lineup
              </ResponsiveIconButton>
            </Toolbar>

            {!form.matchLineupPlayers?.filter((c) => c.playerId !== null) || form.matchLineupPlayers?.filter((c) => c.playerId !== null).length === 0 ? (
              <EmptyView>No Player Lineup</EmptyView>
            ) : (
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.narrowPadding}>
                    Player
                    </TableCell>
                    <TableCell className={classes.narrowPadding}>
                    Game Number
                    </TableCell>
  
                    <TableCell padding="none" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {form.matchLineupPlayers.map((v, i) => (
                    <TableRow key={i}>
                      <TableCell style={{width: 300}} className={classes.narrowPadding}>
                      <Autocomplete style={{ marginTop: 9 }}
                        disabled={!(match && (new Date() >= new Date(format(subHours(new Date(match?.matchDate), 24), 'yyyy-MM-dd HH:mm:ss')) && new Date() <= new Date(format(subMinutes(new Date(match?.matchDate), 30), 'yyyy-MM-dd HH:mm:ss'))))}
                        value={matchData?.teamPlayers?.find((s) => v.playerId && s.playerId === parseInt(v.playerId.toString())) || null}
                        multiple={false}
                        options={
                          matchData?.teamPlayers?.filter(
                            (t) =>
                                t.player.isActive &&
                                !form.matchLineupPlayers
                                ?.filter((c) => Boolean(c.playerId))
                                .map((c) => c.playerId)
                                .includes(t.playerId),
                          ) ?? []
                        }
                        getOptionLabel={(option: TeamPlayer) => option?.player.name + ' ' + option?.player.surname ?? ''}
                        onChange={(e: React.ChangeEvent<{}>, teamPlayer: TeamPlayer | null) =>
                          onChangePlayerTeams(teamPlayer?.playerId, 'playerId', i)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="playerId"
                            variant="standard"
                            label=""
                            inputProps={{
                              ...params.inputProps,
                            }}
                            required={true}
                          />
                        )}
                      />
                      </TableCell>
  
                      <TableCell className={classes.narrowPadding}>
                        <FormField
                          disabled={!(match && (new Date() >= new Date(format(subHours(new Date(match?.matchDate), 24), 'yyyy-MM-dd HH:mm:ss')) && new Date() <= new Date(format(subMinutes(new Date(match?.matchDate), 30), 'yyyy-MM-dd HH:mm:ss'))))}
                          fullWidth={false}
                          required={true}
                          InputProps={{ inputProps: { min: "1" } }}
                          type="number"
                          value={v.gameNumber ?? ''}
                          onChange={(e) => onChangePlayerTeams(e.currentTarget.value, 'gameNumber', i)}
                        />
                      </TableCell>
  
                      <TableCell padding="none">
                        <IconButton
                          className={classes.removeIcon}
                          onClick={(e) => onRemovePlayerTeam(e, i)}
                          disabled={!(match && (new Date() >= new Date(format(subHours(new Date(match?.matchDate), 24), 'yyyy-MM-dd HH:mm:ss')) && new Date() <= new Date(format(subMinutes(new Date(match?.matchDate), 30), 'yyyy-MM-dd HH:mm:ss'))))}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Grid>
        </Grid>
      </FormDialog>
    );
  
    function onAddPlayerTeam(e: React.MouseEvent<HTMLElement>) {
      const matchLineupPlayers = form.matchLineupPlayers?.slice() ?? [];
      if (!matchData?.teamPlayers) {
        return null;
      }

      const data = matchData?.teamPlayers[0];

      if (form.matchLineupPlayers && form.matchLineupPlayers.length + 1 > 16) {
        return null;
      }

      matchLineupPlayers.push({
        playerId: 0,
        teamId : data.teamId
      });
  
      setForm((f) => ({
        ...f,
        matchLineupPlayers,
      }));
    }
  
    function onChangePlayerTeams(
      value: any,
      name: 'playerId' | 'gameNumber',
      i: number,
    ) {
      if (!form.matchLineupPlayers) {
        return null;
      }

      if (name === 'gameNumber' && value !== "" && (value < 1 || value > 99)) {
        return ;
      }
      const matchLineupPlayers = form.matchLineupPlayers.slice();
      const data = matchLineupPlayers[i];
      
      switch (name) {
        case 'playerId':
        case 'gameNumber':
          data[name] = value ?? '';
          break;
  
        default:
          return;
      }
  
      setForm((f) => ({
        ...f,
        matchLineupPlayers,
      }));
    }
  
    function onRemovePlayerTeam(e: React.MouseEvent<HTMLElement>, i: number) {
      if (!form.matchLineupPlayers) {
        return null;
      }
  
      const matchLineupPlayers = form.matchLineupPlayers.slice();
  
      matchLineupPlayers.splice(i, 1);
  
      setForm((f) => ({ ...f, matchLineupPlayers }));
    }

    function onAddClubOfficialTeam(e: React.MouseEvent<HTMLElement>) {
      const matchLineupOfficials = form.matchLineupOfficials?.slice() ?? [];

      if (!matchData?.clubOfficials) {
        return null;
      }

      if (!matchData?.teamPlayers) {
        return null;
      }

      const data = matchData?.teamPlayers[0];

      if (form.matchLineupOfficials && form.matchLineupOfficials.length + 1 > 4) {
          return null;
      }

      matchLineupOfficials.push({
        clubOfficialId: 0,
        teamId : data.teamId
      });
  
      setForm((f) => ({
        ...f,
        matchLineupOfficials,
      }));
    }
  
    function onChangeClubOfficialLineup(
      value: any,
      name: 'clubOfficialId' | 'gameNumber',
      i: number,
    ) {
      if (!form.matchLineupOfficials) {
        return null;
      }

      if (name === 'gameNumber' && value === 'B' && form.matchLineupOfficials?.filter((x)=> x.gameNumber === 'A').length !== 1) {
        return ''
      }
      if (name === 'gameNumber' && value === 'C' && form.matchLineupOfficials?.filter((x)=> x.gameNumber === 'A' || x.gameNumber === 'B').length !== 2) {
        return ''
      }
      if (name === 'gameNumber' && value === 'D' && form.matchLineupOfficials?.filter((x)=> x.gameNumber === 'A' || x.gameNumber === 'B' || x.gameNumber === 'C').length !== 3) {
        return ''
      }
      const matchLineupOfficials = form.matchLineupOfficials.slice();
      const data = matchLineupOfficials[i];
      
      switch (name) {
        case 'clubOfficialId':
        case 'gameNumber':
          data[name] = value ?? '';
          break;
  
        default:
          return;
      }
  
      setForm((f) => ({
        ...f,
        matchLineupOfficials,
      }));
    }
  
    function onRemoveClubOfficialTeam(e: React.MouseEvent<HTMLElement>, i: number) {
      if (!form.matchLineupOfficials) {
        return null;
      }
  
      const matchLineupOfficials = form.matchLineupOfficials.slice();
  
      matchLineupOfficials.splice(i, 1);
  
      setForm((f) => ({ ...f, matchLineupOfficials }));
    }
    
    function hasDuplicates(playerNumbers : (string | undefined)[], lineup: any) {
      return (new Set(playerNumbers)).size !== lineup.length;
    }
  
    async function submit(e: React.FormEvent<HTMLFormElement>) {
      try {
        setLoading(true);
  
        const data = { ...form};
  
        if (form.matchLineupOfficials === undefined || form.matchLineupOfficials?.length < 1) {
          return snackbar.error("Minimum of 1 team official must be selected in the lineup");
        }

        if (form.matchLineupPlayers === undefined || form.matchLineupPlayers?.length < 5) {
          return snackbar.error("Minimum of 5 players must be selected in the lineup");
        }

        if (form.matchLineupPlayers.length > 16) {
          return snackbar.error("Maximum of 16 players must be selected in the lineup");
        }

        let lineUpNumbers =  form.matchLineupPlayers.map((x) => x.gameNumber);
        if (hasDuplicates(lineUpNumbers, form.matchLineupPlayers)) {
          return snackbar.error("Lineup contains duplicates shirt numbers");
        }
        const response = await ApiClient.put(`match-lineups/${match?.id}`, data);
  
        if (!response) {
          return;
        }
  
        if (dialogProps.onClose) {
          dialogProps.onClose(e);
        }
        
        refetch();

        snackbar.open('Match Lineup has been saved successfully.');
      } catch (error) {
        snackbar.error(error);
      } finally {
        setLoading(false);
      }
    }
  };
  