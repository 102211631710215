import { SvgIcon } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import * as React from 'react';
import { RouteProps } from 'react-router-dom';
import { Dashboard, MatchLineups, Seasons, Clubs } from 'views';
import HomeIcon from '@material-ui/icons/Home';

interface SubPage {
  path: string;
  headerTitle: string;
  icon: typeof SvgIcon;
  searchTitle: string;
}

// Children not supported by AppLayout
export interface AppRouteProps extends Omit<RouteProps, 'children'> {
  path: string;
  headerTitle: string;
  sidebarName?: React.ReactNode;
  icon: typeof SvgIcon;
  subPages?: SubPage[];
}

export function getRoutes() {
  const routes: AppRouteProps[] = [
    {
      path: '/dashboard',
      headerTitle: 'Dashboard',
      icon: DashboardIcon,
      component: Dashboard,
    },
    {
      path: '/clubs',
      headerTitle: 'Clubs',
      icon: HomeIcon,
      component: Clubs,
    },
    {
      path: '/seasons',
      headerTitle: 'Seasons',
      icon: SportsHandballIcon,
      component: Seasons,
    },
    {
      path: '/match-lineups',
      headerTitle: 'Match Lineups',
      icon: SportsHandballIcon,
      component: MatchLineups,
    },
  ];

  return routes;
}
